import { useState } from 'react';
import { addRemanAndDepositItemsToCart, getAvailabilityDisplay, updateCartItem } from '../../hooks';
import { RemanResults, SearchResultItem } from '../../types';
import { ButtonResult, ButtonType, PopupSize, popup } from '../../utilities';
import { Mode, QuantitySelector } from '../QuantitySelector/QuantitySelector';
import { RemanufacturedChoice } from '../RemanufacturedChoice/RemanufacturedChoice';
import * as styles from './Item.module.scss';
import cn from 'classnames';

type ItemProps = {
    item: SearchResultItem;
    showInventoryPopup: (item: SearchResultItem, ev: React.MouseEvent) => void;
};

const isLoggedIn = (window as any).app.preloadState.isLoggedIn;

export const Item = ({ item, showInventoryPopup }: ItemProps) => {
    const translations = (window as any).app.preloadState.translation;

    const [availabilityDisplay, setAvailabilityDisplay] = useState(item.inventory?.warehouse?.availabilityDisplay);
    const [hasEnoughInStock, setHasEnoughInStock] = useState(item.inventory?.branch?.hasEnoughStock);

    const maximumQuantity = item.inventory?.maximumQuantity ?? 0;
    const minimumQuantity = item.inventory?.minimumQuantity ?? 0;
    const packageQuantity = item.inventory?.packageQuantity ?? 0;
    const quantityStep = item.inventory?.quantityStep ?? 0;
    const isInInventory = item.inventory?.warehouse?.isAvailable ?? false;

    const showRemanufacturePopUp = async (): Promise<RemanResults[string] | undefined> => {

        let result: RemanResults[string] | undefined = undefined;

        const changeResult = (results: RemanResults) => {
            result = results[item.code];
        }

        const override = item.remanQuantity
        ? {
            [item.code]: item.remanQuantity
        } : undefined;

        const popupResult = await popup(
            translations["remanufacturedItemPopUp.selectItemToAddToCart"],
            <RemanufacturedChoice changeResult={changeResult} sparePartIds={[item.code]} quantityOverride={override} />,
            [
                { label: translations["remanufacturedItemPopUp.remove"], result: ButtonResult.No, type: ButtonType.Link, className: styles.deleteButton, visible: override != null },
                { label: translations["remanufacturedItemPopUp.cancel"], result: ButtonResult.Cancel, type: ButtonType.Outlined },
                { label: translations["remanufacturedItemPopUp.ok"], result: ButtonResult.Ok, type: ButtonType.Primary }
            ],
            PopupSize.ExtraLarge,
            "noPaddingsPopUp"
        );

        if (popupResult == ButtonResult.Ok) {
            return result;
        } else if (popupResult == ButtonResult.No) {
            return {
                selectedReman: false,
                sparePartQuantity: 0,
                remanQuantity: 0
            };
        } else {
            return undefined;
        }
    }

    const updateItem = async (code: string, quantity: number) => {
        await updateCartItem(code, quantity);
        item.remanQuantity = 0;

        const stockAvailability = await getAvailabilityDisplay(code);
        if (stockAvailability.availability) {
            setAvailabilityDisplay(stockAvailability.availability);
        }
        setHasEnoughInStock(stockAvailability.hasEnoughInStock);
    }

    const onAddReman = async (quantity: number) => {
        await addRemanAndDepositItemsToCart(item.code, quantity);
        item.remanQuantity = quantity;
    }

    return (
            <div className={styles.wrapper}>
                <a href={item.url}>
                    <div className={styles.group}>
                        <img className={cn(styles.mobileImage, "objectFitContain")} src={item.imageUrl + "?height=180"} alt={item.displayName} />
                        <div className={styles.infoBlock}>
                            <p title={item.displayName} className={styles.title}>{item.displayName}</p>
                            <p className={cn(styles.articleNumber, "firstLetterCapital")}>
                                {item.code && `${translations["sparePartItem.articleNo"]}: ${item.code}`}{" "}
                            </p>
                            {item.labels?.length > 0 &&
                                <p className={cn(styles.labels, "d-md-none")}>
                                    {item.labels.map(label =>
                                        <span key={label.text} className={styles.label}>{label.text}</span>
                                    )}
                                </p>
                            }
                            <p className={styles.additionalInfo}>
                                {packageQuantity > 0 && <span className={"d-inline-block firstLetterCapital"}>{translations["sparePartItem.quantityFrp"]}: {packageQuantity}</span>}
                                {!!item.itemUnitId && <span className={"d-inline-block firstLetterCapital"}>{translations["sparePartItem.unit"]}: {item.itemUnitId}</span>}
                                {item.mode !== Mode.AddToCart ? <>
                                    <span
                                        className={cn("d-md-none truckIcon", {
                                            [styles.redTruck]: !isInInventory,
                                            [styles.truck]: isInInventory
                                        })}
                                    >
                                        {isInInventory ? <span dangerouslySetInnerHTML={{ __html: availabilityDisplay ?? "" }}></span> : <span className={"d-inline-block firstLetterCapital"}>{translations["inventoryPopup.notInStock"]}</span>}
                                    </span>
                                    {item.inventory?.branch?.branchName ?
                                        <span
                                            onClick={(ev) => showInventoryPopup(item, ev)}
                                            className={cn(styles.inventoryIndicator, "d-md-none", {
                                                [styles.check]: hasEnoughInStock,
                                                [styles.close]: !hasEnoughInStock,
                                            })}
                                        >
                                            {item.inventory?.branch?.branchName}
                                        </span>
                                        :
                                        <span
                                            onClick={(ev) => showInventoryPopup(item, ev)}
                                            className={cn(styles.inventoryIndicator, "d-md-none")}
                                        >
                                            {translations["inventories.selectBranch"]}
                                        </span>
                                    }
                                </> : <span className={cn("d-md-none truckIcon", styles.truck)}>
                                    <span className={"d-inline-block firstLetterCapital"}>{translations["common.deliveryTimeToBeChecked"]}</span>
                                </span>}
                            </p>
                        </div>
                    </div>
                    {isLoggedIn && item.mode !== Mode.AddToCart && <div className={styles.prices}>
                        {item.percentageDiscount > 0 && (
                            <div className={styles.nmd}>
                                <p className={styles.discountedPrice}>{item.discountedPrice}</p>
                            </div>
                        )}
                        <div>
                            <p
                                className={cn({
                                    [styles.boldText]: item.percentageDiscount <= 0,
                                    [styles.originalPrice]: item.percentageDiscount > 0,
                                    [styles.discountedPrice]: item.percentageDiscount > 0,
                                    [styles.strike]: item.percentageDiscount > 0,
                                    [styles.smallFont]: item.percentageDiscount > 0
                                })}
                            >
                                <span className={styles.nmd}>{item.unitPrice}</span>
                            </p>
                            <span className={cn(styles.md_inline, styles.discountedPrice)}>{item.discountedPrice ?? item.unitPrice}</span>
                        </div>
                        {item.percentageDiscount > 0 && (
                            <>
                                <div>
                                    <span className={cn(styles.md_inline, styles.strike, styles.secondary)}>{item.unitPrice}</span>
                                </div>
                                <div>
                                    <div className={cn("firstLetterCapital d-inline-block", styles.secondary)}>{translations["sparePartItem.discount"]}:</div>
                                    <div className="d-inline-block">
                                        <span className={styles.discount}>{item.discountPercentageDisplay}</span>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>}
                <div className={styles.availabilityIndicators}>
                        {item.mode !== Mode.AddToCart ? <>
                            <span
                                className={cn("d-none d-md-block truckIcon", styles.smallFont, {
                                    [styles.redTruck]: !isInInventory,
                                    [styles.truck]: isInInventory,
                                })}
                            >
                                {isInInventory ? <span dangerouslySetInnerHTML={{ __html: availabilityDisplay ?? "" }}></span> : <span className={"d-inline-block firstLetterCapital"}>{translations["inventoryPopup.notInStock"]}</span>}
                            </span>
                            {item.inventory?.branch?.branchName ?
                                <span
                                    onClick={(ev) => showInventoryPopup(item, ev)}
                                    className={cn(styles.inventoryIndicator, "d-none d-md-block", styles.smallFont, styles.smallMarginTop, {
                                        [styles.check]: hasEnoughInStock,
                                        [styles.close]: !hasEnoughInStock,
                                    })}
                                >
                                    {item.inventory?.branch?.branchName}
                                </span>
                                :
                                <span
                                    onClick={(ev) => showInventoryPopup(item, ev)}
                                    className={cn(styles.inventoryIndicator, "d-none d-md-block", styles.smallFont, styles.smallMarginTop)}
                                >
                                    {translations["inventories.selectBranch"]}
                                </span>
                            }
                        </> : <span className={cn("d-none d-md-block truckIcon", styles.smallFont, styles.truck)}>
                            <span className={"d-inline-block firstLetterCapital"}>{translations["common.deliveryTimeToBeChecked"]}</span>
                        </span>}
                    </div>
                    <p className={cn(styles.labels, "d-none d-md-block")}>
                        {item.labels?.length > 0 &&
                            <span key={item.labels[0].text} className={styles.label}>{item.labels[0].text}</span>
                        }
                    </p>
                </a>
                <div className={styles.quantity}>
                <QuantitySelector forceMinValue={false}
                        mode={item.mode}
                        requestQuotationByEmail={item.requestQuotationByEmail}
                        contactSupportLink={item.contactSupportLink}
                        className={styles.buttonClass}
                        hasRemanInCart={!!item.remanQuantity}
                        step={quantityStep}
                        min={minimumQuantity}
                        max={maximumQuantity}
                        initialValue={item.quantity}
                        onRemove={(f: () => void) => updateItem(item.code, 0).then(() => f())}
                        onChange={(quantity: number) => updateItem(item.code, quantity)}
                        showRemanufacturePopUp={item.hasRemanItem ? showRemanufacturePopUp : undefined}
                        onAddReman={onAddReman}
                        hasRemanAvailable={item.hasRemanItem}
                    ></QuantitySelector>
                </div>
            </div>

    );
};
