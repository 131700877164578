import React, { useEffect, useMemo, useRef, useState, useSyncExternalStore, useTransition } from "react"
import { ButtonResult, ButtonType, InputCheckbox, InputRadio, InputRange, PopupSize, popup } from "ui";
import { Inventories } from "../Inventories/Inventories";
import * as styles from './SparePartsList.module.scss';
import { Item } from "./Item";
import { SearchResultItem } from "../../types";
import cn from 'classnames';

const translationState = (window as any).app.preloadState.translation;
const showInventoryPopup = (item: SearchResultItem, ev: React.MouseEvent) => {

    ev.preventDefault();

    popup(
        translationState["inventoryPopup.stockStatusAtFacility"],
        <Inventories itemCode={item.code} itemDisplayName={item.displayName} />,
        [
            { label: translationState["inventoryPopup.cancel"], result: ButtonResult.Cancel, type: ButtonType.Primary }
        ],
        PopupSize.Large
    );
}

export type FacetValue = {
    key: string;
    values: string[];
}

type SparePartsListProps = {
    items: SearchResultItem[];
    total: number;
    facets: FacetData[];
    pageCallback: (page: number, facets: FacetValue[], sort: SparePartsSortField, sortAscending: boolean) => Promise<SearchResultItem[]>;
}

export const SparePartsList = ({ items, facets, total, pageCallback }: SparePartsListProps) => {

    const [firstItems, setFirstItems] = useState([items, 0] as [SearchResultItem[], number]);

    const numberOfDivs = items.length > 0 ? Math.ceil(total / items.length) : 1;

    const mapping = Array.from(Array(numberOfDivs - 1).keys())

    const reloadData = () => {
        setTimeout(() => {
            urlChanged.forEach(c => c());
            callback(1).then(data => setFirstItems(p => [data, p[1] + 1]));
        }, 0);
    };

    const callback = async (page: number) => {
        const params = new URLSearchParams(location.search);

        const facetValues = facets
            .filter(facet => params.has(facet.name))
            .map(facet => ({
                key: facet.name,
                values: params.get(facet.name)!.split(',')
            }));

        const sortValue = params.get('sort') ?? SparePartsSortField.Best;
        const sortDirectionValue = params.get('sortDirection') ?? 'desc';

        let sort: SparePartsSortField;
        let sortAscending: boolean;

        switch (sortValue) {
            case SparePartsSortField.Best:
            case SparePartsSortField.Name:
            case SparePartsSortField.Weight:
            case SparePartsSortField.Price:
                [sort, sortAscending] = [sortValue, sortDirectionValue === 'asc'] as [SparePartsSortField, boolean];
                break;
            default:
                [sort, sortAscending] =[SparePartsSortField.Best, sortDirectionValue === 'asc'] as [SparePartsSortField, boolean];
                break;
        }

        return await pageCallback(page, facetValues, sort, sortAscending);
    }

    return <>
        <Sorter reload={reloadData} />
        <div className={styles.sparePartItemsModule}>
            <div className={styles.spartPartsFacets}>
                {facets.map(facet => <Facet facet={facet} key={facet.name} reload={reloadData} />) }
            </div>
            <div className={styles.sparePartsList}>
                <div className={styles.sparePartItems}>
                    {firstItems[0].map(item => <Item
                        key={item.code}
                        item={item}
                        showInventoryPopup={showInventoryPopup}
                    />)}
                </div>
                {mapping.map((x) => {
                    return <SparePartsObserver key={x + '_' + firstItems[1]} page={x + 2} pageCallback={callback} />
                })}
            </div>
        </div>
    </>
}

const SparePartsObserver = ({ page, pageCallback }: { page: number, pageCallback: (page: number, ) => Promise<SearchResultItem[]> }) => {
    const [items, setItems] = useState<SearchResultItem[]>([]);
    const loaded = useRef(false);

    const observer = useMemo(() => {
        const classOptions = {
            root: document.rootElement,
            rootMargin: "200px 0px",
            threshold: 0,
        };

        const callback = async (entries: IntersectionObserverEntry[]) => {
            if (entries[0].isIntersecting && !loaded.current) {
                const data = await pageCallback(page);
                setItems(data);
                loaded.current = true;
            }
        }

        return new IntersectionObserver(callback, classOptions);
    }, []);

    useEffect(() => {
        let target = document.querySelector(`#list_${page}`);
        observer.observe(target!);
    }, [])

    return <>
        {items.length ?
            <div className={styles.sparePartItems}>
                {items.map(item => <Item
                    key={item.code}
                    item={item}
                    showInventoryPopup={showInventoryPopup}
                />)}
            </div>
            :
            <div id={`list_${page}`} className={styles.itemsPlaceholder}>

            </div>
        }
        </>
}

type BaseFacet = {
    name: string;
    header: string;
    infoPopUp: InfoPopUp | null;
};

type InfoPopUp = {
    title: string;
    description: string;
};

type CheckboxFacet = BaseFacet & {
    type: 'checkbox';
    options: { label: string; value: string, disabled: boolean }[];
};

type RangeFacet = BaseFacet & {
    type: 'range';
    min: number;
    max: number;
};

export type FacetData = CheckboxFacet | RangeFacet;

let urlChanged: (() => void)[] = [];

const urlSubscribe = (callback: () => void) => {
    urlChanged.push(callback);
    return () => {
        urlChanged = urlChanged.filter(c => c != callback);
    }
}

const Facet = ({ facet, reload }: { facet: FacetData, reload: () => void }) => {
    const [open, setOpen] = useState(true);

    const showInfoPopup = (ev: React.MouseEvent) => {
        if (!facet.infoPopUp) return;
        ev.stopPropagation();
        popup(
            facet.infoPopUp.title,
            <div dangerouslySetInnerHTML={{ __html: facet.infoPopUp.description }}></div>,
            [
                { label: translationState["sparePartVariationButtonOptions.close"], result: ButtonResult.Cancel, type: ButtonType.Primary }
            ],
            PopupSize.Small
        );
    }

    const ActualFacet = () => {
        switch (facet.type) {
            case 'checkbox':
                return <FacetCheckboxes facet={facet} reload={reload} />
            case 'range':
                return <FacetRange facet={facet} reload={reload} />
        }
    }
    return <div>
        <h3 className="heading--s" onClick={() => setOpen(!open)}>
            <span>
                {facet.header}
                {(facet.infoPopUp != null) && <span className={styles.popupIcon} onClick={showInfoPopup}></span>}
            </span>
            <span className={cn(styles.chevron, open ? styles.chevronUp : styles.chevronDown)}></span>
        </h3>
        <hr/>
        {open && ActualFacet()}
    </div>;
}

const FacetCheckboxes = ({ facet, reload }: { facet: CheckboxFacet, reload: () => void }) => {

    const urlData = useSyncExternalStore(
        urlSubscribe,
        () => new URLSearchParams(location.search).get(facet.name)
    );

    const selectedValues = useMemo(() => {
        if (urlData == null) return new Set<string>();
        return new Set<string>(urlData.split(',').filter(s => !!s));
    }, [urlData]);

    const toggleFacet = (value: string) => {
        if (selectedValues.has(value)) {
            selectedValues.delete(value);
        } else {
            selectedValues.add(value);
        }
        const params = new URLSearchParams(location.search);
        if (selectedValues.size == 0) {
            params.delete(facet.name);
        } else {
            params.set(facet.name, Array.from(selectedValues).join(','));
        }
        window.history.replaceState(null, "", location.pathname + '?' + params.toString());
        reload();
    }

    return <>{
        facet.options.map(option => <InputCheckbox key={option.value} label={option.label} name={facet.name} disabled={option.disabled} checked={selectedValues.has(option.value)} onChange={() => toggleFacet(option.value)} />)
    }</>;
}

const FacetRange = ({ facet, reload }: { facet: RangeFacet, reload: () => void }) => {

    const urlData = useSyncExternalStore(
        urlSubscribe,
        () => new URLSearchParams(location.search).get(facet.name)
    );

    const selectedValue = useMemo(() => {
        if (urlData == null) return { low: facet.min, high: facet.max };
        const [low, high] = urlData.split(',');
        const data = { low: parseInt(low, 10), high: parseInt(high, 10) };
        if (data.low > data.high) return { low: facet.min, high: facet.max };
        return data;
    }, [urlData, facet.min, facet.max]);

    const toggleFacet = (value: { low: number, high: number }) => {
        const params = new URLSearchParams(location.search);
        params.set(facet.name, value.low + ',' + value.high);
        window.history.replaceState(null, "", location.pathname + '?' + params.toString());
        reload();
    };

    return <InputRange
        max={facet.max}
        min={facet.min}
        value={selectedValue}
        onBlur={toggleFacet}
    />
}

export enum SparePartsSortField {
    Best = 'Best',
    Name = 'Name',
    Weight = 'Weight',
    Price = 'Price'
}

const Sorter = ({ reload }: { reload: () => void }) => {
    const [open, setOpen] = useState(false);

    const urlData = useSyncExternalStore(
        urlSubscribe,
        () => {
            const params = new URLSearchParams(location.search);
            return (params.get('sort') ?? SparePartsSortField.Best) + ';' + (params.get('sortDirection') ?? 'desc');
        }
    );

    const sorting = useMemo(() => {
        const [sort, sortDirection] = urlData.split(';');
        switch (sort) {
            case SparePartsSortField.Best:
            case SparePartsSortField.Name:
            case SparePartsSortField.Weight:
            case SparePartsSortField.Price:
                return [sort, sortDirection === 'asc'] as [SparePartsSortField, boolean];
            default:
                return [SparePartsSortField.Best, sortDirection === 'asc'] as [SparePartsSortField, boolean];
        }
    }, [urlData]);

    const toggleSorting = (value: SparePartsSortField, ascending: boolean) => {
        const params = new URLSearchParams(location.search);
        params.set('sort', value);
        params.set('sortDirection', ascending ? 'asc' : 'desc');
        window.history.replaceState(null, "", location.pathname + '?' + params.toString());
        reload();
    }

    useEffect(() => {
        document.addEventListener("click", (ev) => {
            const filterRadioContainer = document.getElementById("SorterRadioContainer")!;
            const filterRadioButton = document.getElementById("SorterRadioButton")!;
            const target = (ev.target as HTMLDivElement);
            if (filterRadioContainer && !filterRadioContainer.contains(target) && !filterRadioButton.contains(target)) {
                setOpen(false);
            }
        });
    }, []);

    const getLabelFor = (field: SparePartsSortField, ascending: boolean): string => {
        switch (field) {
            case SparePartsSortField.Best:
                return translationState['sorting.best'];
            case SparePartsSortField.Name:
                return ascending ? translationState['sorting.nameAscending'] : translationState['sorting.nameDescending'];
            case SparePartsSortField.Weight:
                return ascending ? translationState['sorting.createdAscending'] : translationState['sorting.createdDescending'];
            case SparePartsSortField.Price:
                return ascending ? translationState['sorting.priceAscending'] : translationState['sorting.priceDescending'];
        }
    }

    const radioHelper = (field: SparePartsSortField, ascending: boolean) => ({
        label: getLabelFor(field, ascending),
        name: "sortingMethod",
        checked: sorting[0] === field && sorting[1] === ascending,
        onChange: () => toggleSorting(field, ascending)
    });

    return <div className={styles.sorterContainer}>
        <button id="SorterRadioButton" className={styles.sortByButton} onClick={() => setOpen(o => !o)}>{translationState["sorting.sortBy"]}: <span className="font-weight-bold">{getLabelFor(sorting[0], sorting[1])}</span><span className={cn(styles.chevron, open ? styles.chevronUp : styles.chevronDown)}></span></button>

        {open && <div className={styles.sorterRadioContainer} id="SorterRadioContainer">
            <div className={styles.sorting}>
                <InputRadio {...radioHelper(SparePartsSortField.Best, false)} />
            </div>
            <div className={styles.sorting}>
                <InputRadio {...radioHelper(SparePartsSortField.Name, true)} />
            </div>
            <div className={styles.sorting}>
                <InputRadio {...radioHelper(SparePartsSortField.Name, false)} />
            </div>
            <div className={styles.sorting}>
                <InputRadio {...radioHelper(SparePartsSortField.Weight, true)} />
            </div>
            <div className={styles.sorting}>
                <InputRadio {...radioHelper(SparePartsSortField.Weight, false)} />
            </div>
            <div className={styles.sorting}>
                <InputRadio {...radioHelper(SparePartsSortField.Price, true)} />
            </div>
            <div className={styles.sorting}>
                <InputRadio {...radioHelper(SparePartsSortField.Price, false)} />
            </div>
        </div>}
    </div>
}